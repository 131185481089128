import React from "react"
import { MainImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"

import Layout from "../_common/layout/default"
import banner from "./../../static/images/placeholder/barra_cofin_FEDER.png"

const Portugal2020_PT = ({ location }) => {
	return (
		<Layout location={location} title="Design & Ecology in Cement Products">
			<main className="block">
				<article className="block" itemScopeitemType="http://schema.org/Article">
					<header className="overflow-x-hidden pt-lg-4">
						<div className="container">
							<div className="row align-items-end">
								<div className="col-lg-8">
									<h1 className="display-4 ls-n2 lh-1 mt-auto mb-lg-0 visually-hidden sr-only">
										Portugal {" "}
										<span className="fw-bold text-primary"> 2020</span>.
									</h1>
									<MainImage className="my-auto img-fluid pt-3" src={banner} width={645} placeholder="none" alt="banner Portugal 2020" />
								</div>
								<div className="col-lg-4 text-lg-end">
									<p className="text-muted mb-0 lh-copy">Consulte-nos e <br className="d-none d-xl-block" /><a href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener" className="text-primary">solicite</a> uma cotação.</p>
								</div>
							</div>
						</div>
					</header>
					<section className="block pb-4 pb-xl-5 pt-xl-4" id="pt2020">
						<div className="container">
							<h1 className="visually-hidden sr-only">Projeto</h1>
							<hr />
							<div className="py-4">
								<div className="row align-items-center gx-5">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Designação do projeto
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Hidralstone inovação produtiva - Indústria inovadora de produtos cimentícios ecológicos e sustentáveis para a construção civil.
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Codigo do Projeto
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											ALT20-01-0853-FEDER-072100
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Objetivo principal
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Reforçar a competitividade das pequenas e médias empresas
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Região de intervenção
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Alentejo, Montemor-o-Novo
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Entidade beneficiária
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											<strong>HIDRALSTONE</strong>
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="py-4">
								<div className="row align-items-center gx-5">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Data da aprovação
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											15-01-2021
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Data de início
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											01-10-2020
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Data de conclusão
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											30-09-2022
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Custo total elegível
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											1.624.022,00 €
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Apoio financeiro da união europeia
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											493.402,93 €
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</article>
				{/* add section */}
			</main>
			<section className="block py-5 bg-hs text-white">
				<div className={"container container-base text-center"}>
					<h2 className="lh-1 mb-2 fs-1">Vamos conversar!</h2>
					<p className="o-66">Conheça nossos produtos e fale connosco através do nosso e-mail <u>info@hidralstone.com</u>.</p>

					<a className="text-inherit fw-demi" href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener">- Comece aqui</a>
				</div>
			</section>
		</Layout>
	)
}

export default Portugal2020_PT
